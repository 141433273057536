import gql from 'graphql-tag'

export const updateUserDetailsMutation = gql`
	mutation($id: ID!, $data: UpdateCustomerInput!) {
		updateCustomer(id: $id, data: $data) {
			id
			username
			email
			phoneNumber
		}
	}
`
