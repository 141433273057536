import React from 'react'
import { isBrowser } from '../../utils/general'
import { Formik, Field, Form } from 'formik'
import jwtDecode from 'jwt-decode'
import * as Yup from 'yup'
import './style.css'
import { useMutation } from '@apollo/react-hooks'
import { updateUserDetailsMutation } from './graphql/mutations'
import { toast } from 'react-toastify'
import { navigate } from 'gatsby'
import Phone from '../Common/PhoneInput'

//formik validations
//username validations
const usernameValidation = Yup.object().shape({
  name: Yup.string()
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Required')
})
//email validation
const emailValidation = Yup.object().shape({
  name: Yup.string()
    .email('Invalid email')
    .required('Required')
})
//phone number validation
const phonevalidation = Yup.object().shape({
  name: Yup.string().required('Please Enter Your Phone Number')
})

//-----------------End of Validation-----------------

const UpdateProfile = ({ state }) => {
  const [updateMutationTrigger, { data, loading, error }] = useMutation(
    updateUserDetailsMutation
  )
  const {
    type = 'email',
    fieldName = 'email',
    fieldValue = 'abc@gmail.com'
  } = state
  const handleSubmitAction = ({ name }) => {
    let token = isBrowser() ? JSON.parse(localStorage.getItem('token')) : {}
    let sessionToken = isBrowser()
      ? JSON.parse(sessionStorage.getItem('token'))
      : {}
    if (token || sessionToken) {
      //now decoding the token
      const { _id } = jwtDecode(token ? token : sessionToken)

      //executing the mutation

      updateMutationTrigger({
        variables: {
          id: _id,
          data: {
            [fieldName]: name
          }
        }
      })
    }
  }
  if (data) {
    toast.success('Updated Successfully', {
      autoClose: 1200
    })
    navigate('/my-account')
  }
  if (error) {
    toast.error(error.message, {
      autoClose: 1200
    })
  }

  if (loading) {
    console.log('graph loading', loading)
  }
  return (
    <div className='container-fluid d-flex hm-login-bg justify-content-center align-items-center'>
      <div id='forgotPassword'>
        <div className='forgotPasswordTextView'>
          <p>Update your {type}?</p>
        </div>
        <div className='instructionText'>
          <p>Enter your {type} to Update it</p>
        </div>
        <div className='inputstyleview'>
          <Formik
            initialValues={{
              name: ''
            }}
            validationSchema={
              fieldName === 'username'
                ? usernameValidation
                : fieldName === 'email'
                ? emailValidation
                : fieldName === 'phoneNumber'
                ? phonevalidation
                : usernameValidation
            }
            onSubmit={values => handleSubmitAction(values)}
          >
            {({ values, errors, touched, handleSubmit }) => (
              <>
                <Form className='formStyle'>
                  {fieldName === 'phoneNumber' ? (
                    <div className='phoneInputStyle'>
                      <Field name='name' component={Phone} />
                    </div>
                  ) : (
                    <Field
                      className='inputStyle'
                      type='text'
                      name='name'
                      placeholder={fieldValue}
                    />
                  )}

                  {errors.name &&
                  touched.name &&
                  fieldName !== 'phoneNumber' ? (
                    <div className='bg-danger my-1 p-0 col-12 rounded'>
                      <p className='text-white p-1 m-0'>{errors.name}</p>
                    </div>
                  ) : null}
                  <div className='loginButtonStyle'>
                    <p onClick={handleSubmit} className='loginButtonText'>
                      Update
                    </p>
                  </div>
                </Form>
              </>
            )}
          </Formik>
        </div>
      </div>
    </div>
  )
}

export { UpdateProfile }
