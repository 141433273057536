import React from 'react'
import Layout from '../components/Layout/layout'
import SEO from '../components/SEO/seo'
import { UpdateProfile } from '../components/UpdateProfile'
import { isLoggedIn } from '../utils/protectedRoute'
const Login = ({ location }) => {
	const { state = {} } = location

	return !isLoggedIn() ? (
		<h1>you are not loggedIn </h1>
	) : (
		<Layout>
			<SEO title='Update Profile' />
			<UpdateProfile state={state} />
		</Layout>
	)
}

export default Login
